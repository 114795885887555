@use "../../../styles/functions" as *;

@if isSbim() {
  :root:has(#tableLabel) > body {      
        background-image: url("../../../../public/imgs/sbim/blue.jpg");          
  }

  :root:has(#tableLabel) * .container {
    background-color: ivory;
  }

  #createOrganisationButton {
    margin-top: 0.5rem;
  }

  #OrganisationTable * th {
    text-align: center;
  }
}